import axios, { AxiosResponse } from 'axios'
import { AdminPortalAPISimpleResponse } from '@/models/api-response.model'

export const submitPromoteProject = async (
  additionalInfo: string
): Promise<AdminPortalAPISimpleResponse> => {
  const query = JSON.stringify({
    additionalInfo: additionalInfo,
  })

  const res: AxiosResponse<AdminPortalAPISimpleResponse> = await axios.post(
    `/api/users/access-request`,
    query,
    {
      headers: { 'content-type': 'application/json' },
    }
  )
  return res.data
}

export const submitPromoteProjectForVerifiedOrg = async (
  additionalInfo: string
): Promise<AdminPortalAPISimpleResponse> => {
  const query = JSON.stringify({
    additionalInfo: additionalInfo,
  })

  const res: AxiosResponse<AdminPortalAPISimpleResponse> = await axios.post(
    `/api/email/contact`,
    query,
    {
      headers: { 'content-type': 'application/json' },
    }
  )
  return res.data
}