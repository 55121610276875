import React, { useState } from 'react'
import {
  ModalCloseEventObject,
  ShieldNotificationItem,
} from '@/models/shield.model'
import {
  UpdatedProjectApiProduct,
  UpdatedSandboxProject,
} from '@/modules/credentials/projects/projectDetails/projectDetails.model'
import USBModal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@usb-shield/react-modal'
import ReactLoadingInline from '@/components/ReactLoaders/ReactLoadingInline'
import USBButton from '@usb-shield/react-button'
import USBButtonGroup from '@usb-inner-src/react-button-group'
import USBLink from '@usb-shield/react-link'
import USBNotification from '@usb-shield/react-notification'
import actionStyles from '@/modules/credentials/projects/components/ProjectActions/ProjectActions.module.scss'
import {
  promoteCTAErrorModalDataJson as promoteErrorModalData,
  promoteCTAPageDataJson as promotePageData,
} from '@/modules/credentials/projects/components/PromoteCTA/promoteCTA.content'

import styles from './PromoteCTA.module.scss'
import {
  submitPromoteProject,
  submitPromoteProjectForVerifiedOrg,
} from './services/promote.service'
import { useSession } from 'next-auth/react'
import { UserOrganization } from '@/models/user.model'
import { useRouter } from 'next/router'

const PromoteCTA = ({
  ctaText,
  accessDashboardRequest,
  onDashboardPage,
  projectDetails,
  documentDetails,
  type,
  autoOpen,
  promoteSuccessHandler,
  accessRequestSuccessHandler,
  tealiumSuccessHandler,
}: {
  ctaText: string
  accessDashboardRequest?: string
  projectDetails?: UpdatedSandboxProject
  type: 'button' | 'buttonWithIcon' | 'link' | 'secondaryButton'
  documentDetails?: {
    productName: string
    documentLink?: string | string[]
  }
  promoteSuccessHandler?: () => void
  accessRequestSuccessHandler?: () => void
  tealiumSuccessHandler?: () => void
  autoOpen?: boolean
  onDashboardPage?: boolean
}) => {
  const { data: session } = useSession()
  const verifiedOrg: UserOrganization | null | undefined =
    session?.decodedAccessToken?.organization
  const [promoteModalIsOpen, setPromoteModalIsOpen] = useState<boolean>(false)
  const [notificationData, setNotificationData] = useState<
    ShieldNotificationItem[]
  >([])
  const [processing, setProcessing] = useState<boolean>(false)
  const [autoOpened, setAutoOpened] = useState<boolean>(false)
  // Modal condtion for error case
  const [errorPromoteModal, setErrorPromoteModal] = useState<boolean>(false)

  /* Promote button clicked */
  const onPromoteClick = () => {
    if (notificationData) {
      setNotificationData([])
    }
    setErrorPromoteModal(false)
    setPromoteModalIsOpen(true)
  }

  if (
    autoOpen &&
    !autoOpened &&
    session &&
    !session?.decodedAccessToken?.organization
  ) {
    if (notificationData) {
      setNotificationData([])
    }
    setErrorPromoteModal(false)
    setPromoteModalIsOpen(true)
    setAutoOpened(true)
  }

  const siteId: string = String(process.env.APIGEE_API_ENVIRONMENT)
  // getting all the product lists based on comma seperator
  const getProductLists = (productList: Array<UpdatedProjectApiProduct>) => {
    return Array.isArray(productList)
      ? productList.map((p) => p.apiproduct).join(', ')
      : ''
  }

  const getAdditionalInfo = () => {
    if (projectDetails)
      return `Promote ${
        projectDetails.displayName
      } from ${siteId} to UAT products: ${getProductLists(
        projectDetails.apiProducts
      )}`
    else if (documentDetails?.documentLink)
      return `Request Access for ${documentDetails?.productName}, Link =${documentDetails?.documentLink}`
    else if (accessDashboardRequest) return accessDashboardRequest
    else
      return `Request to create a sandbox project for ${documentDetails?.productName}`
  }

  // check if dashboard feature exists and user is not verified
  let dashboardFlag = false
  if (
    !verifiedOrg &&
    process.env.FEATURE_DEVELOPER_DASHBOARD !== 'false' &&
    !onDashboardPage
  ) {
    dashboardFlag = true
  }

  const router = useRouter()
  const handlesubmit = async () => {
    setProcessing(true)
    setNotificationData([])

    if (dashboardFlag) {
      router.push('/dashboard')
    } else {
      try {
        await handleProjectPromotion()
      } catch (err: any) {
        handlePromotionError(err)
      }
    }
  }

  const handleProjectPromotion = async () => {
    if (!verifiedOrg) {
      await submitPromoteProject(getAdditionalInfo())
    } else {
      await submitPromoteProjectForVerifiedOrg(getAdditionalInfo())
    }
    //Tealium.verifyOrgModalClickHandler()
    setProcessing(false)
    setPromoteModalIsOpen(false)
    if (promoteSuccessHandler) {
      promoteSuccessHandler()
    }
    if (accessRequestSuccessHandler) {
      accessRequestSuccessHandler()
    }
    if (tealiumSuccessHandler) {
      tealiumSuccessHandler()
    }
  }

  const handlePromotionError = (err: any) => {
    setProcessing(false)
    // Set error messages from the backend response
    const errorMessages: ShieldNotificationItem[] = err?.response?.data
      ?.errors || [{ text: 'Something went wrong' }]
    // If the access request already sent by the user then promote error modal will open
    const ACCESS_REQUEST_ALREADY_SEND_BY_USER_TEXT =
      'Your request is currently being processed.'
    if (
      errorMessages.length > 0 &&
      errorMessages[0].text.includes(ACCESS_REQUEST_ALREADY_SEND_BY_USER_TEXT)
    ) {
      setErrorPromoteModal(true)
    } else {
      // Set page level error messages
      setNotificationData(errorMessages)
    }
  }

  return (
    <>
      {ctaText && type == 'buttonWithIcon' && !autoOpen && (
        <div className="modalCta">
          <USBButton
            dataTestId="promoteModal"
            type="button"
            name={`Promote ${projectDetails?.projectName}`}
            ctaStyle="standard"
            emphasis="minimal"
            size="medium"
            handleClick={() => onPromoteClick()}
            iconData={{
              path: {
                d: 'M3.82586 3.37596L4.69463 4.22396L6.92392 2.04796V9.39996H8.15331V2.04796L10.3826 4.22396L11.2514 3.37596L7.973 0.17596C7.73301 -0.0580024 7.34422 -0.0580024 7.10423 0.17596L3.82586 3.37596ZM13.4807 12.8V7.71196H14.71V13.4C14.7056 13.7295 14.433 13.9957 14.0954 14H0.981881C0.644241 13.9957 0.371602 13.7295 0.367188 13.4V7.79996H1.59658V12.8H13.4807Z',
              },
            }}
            iconViewBox={'0 0 15 14'}
            addClasses={actionStyles.actionButton}
          >
            <span data-testid="projectItemPromoteButton">{ctaText}</span>
          </USBButton>
        </div>
      )}
      {ctaText && type === 'button' && !autoOpen && (
        <div className="modalCta">
          <USBButton
            dataTestId="promoteModal"
            type="button"
            name={`Promote ${projectDetails?.projectName}`}
            ctaStyle="standard"
            emphasis="heavy"
            addClasses={styles.promoteButton}
            handleClick={() => onPromoteClick()}
          >
            {ctaText}
          </USBButton>
        </div>
      )}
      {ctaText && type === 'link' && (
        <div className="modalCta">
          <USBLink
            linkType="arrow"
            dataTestId={'promoteLink'}
            handleClick={() => onPromoteClick()}
          >
            {ctaText}
          </USBLink>
        </div>
      )}
      {ctaText && type === 'secondaryButton' && (
        <div className="modalCta">
          <USBButton
            dataTestId="promoteModal"
            type="button"
            size={accessDashboardRequest ? 'small' : 'default'}
            name={`Promote project`}
            ctaStyle="standard"
            emphasis="subtle"
            addClasses={styles.promoteButton}
            handleClick={() => onPromoteClick()}
          >
            {ctaText}
          </USBButton>
        </div>
      )}

      <div className="modalBox">
        <USBModal
          isOpen={promoteModalIsOpen}
          disable="true"
          handleClose={({
            clickedOutsideModal,
            escapeKeyPressed,
          }: ModalCloseEventObject) => {
            if (!processing && !clickedOutsideModal && !escapeKeyPressed) {
              setPromoteModalIsOpen(false)
            }
          }}
        >
          {errorPromoteModal && (
            <ModalHeader id="promote-cta-mh">
              {promoteErrorModalData.modalData.title}
            </ModalHeader>
          )}
          {!errorPromoteModal && (
            <ModalHeader id="promote-cta-mh">
              {dashboardFlag
                ? 'Verified organization required'
                : promotePageData.modalData.title}
            </ModalHeader>
          )}
          <section className={styles.promoteProjectForm}>
            <ModalBody>
              {/* show erros */}
              {notificationData?.length > 0 && (
                <USBNotification
                  variant="error"
                  iconAssistiveText={{ label: 'Error' }}
                  notificationData={notificationData}
                  dataTestId="errorNotification"
                />
              )}
              <div>
                {!errorPromoteModal && (
                  <p>
                    {dashboardFlag
                      ? 'To view documents for this API, your organization must be verified. Go to your Dashboard to view your verification status.'
                      : promotePageData.modalData.description}
                  </p>
                )}
                {errorPromoteModal && (
                  <p>{promoteErrorModalData.modalData.description}</p>
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              <USBButtonGroup addClasses={'modal-button-group'}>
                {!errorPromoteModal && (
                  <USBButton
                    ctaStyle="standard"
                    emphasis="heavy"
                    size="medium"
                    type="submit"
                    id="project_promote"
                    aria-describedby="submit form"
                    handleClick={() => handlesubmit()}
                    addClasses="usb-fleet-continue"
                    disabled={processing}
                  >
                    <div data-testid="promoteModalConfirmButton">
                      {processing && <ReactLoadingInline />}
                      {dashboardFlag
                        ? 'Go to Dashboard'
                        : promotePageData.modalData.buttonLabels.submit}
                    </div>
                  </USBButton>
                )}
                {!errorPromoteModal && (
                  <USBButton
                    id="secondary-modal-button-promote-project"
                    ctaStyle="standard"
                    emphasis="subtle"
                    handleClick={() => {
                      !processing && setPromoteModalIsOpen(false)
                    }}
                    disabled={processing}
                  >
                    <div data-testid="promoteModalCancelButton">
                      {promotePageData.modalData.buttonLabels.cancel}
                    </div>
                  </USBButton>
                )}
                {errorPromoteModal && (
                  <USBButton
                    id="secondary-modal-button-promote-project"
                    ctaStyle="standard"
                    emphasis="subtle"
                    handleClick={() => {
                      !processing && setPromoteModalIsOpen(false)
                    }}
                    disabled={processing}
                  >
                    <div data-testid="promoteErrorModalCloseButton">
                      {promoteErrorModalData.modalData.buttonLabel.close}
                    </div>
                  </USBButton>
                )}
              </USBButtonGroup>
            </ModalFooter>
          </section>
        </USBModal>
      </div>
    </>
  )
}

export default PromoteCTA
