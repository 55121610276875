import { UpdatedSandboxProject } from '@/modules/credentials/projects/projectDetails/projectDetails.model'
import { useMutation } from '@tanstack/react-query'
import projectsService from './sandbox-projects.service'
import { useProjectsCacheUtil } from './sandbox-projects-cache.util'
import { useRouter } from 'next/router'
import { showSuccessToast } from '@/utils/toast/toast'

export const useProjectsMutation = () => {
  const router = useRouter()
  const projectCacheUtil = useProjectsCacheUtil()

  const addProjectMutation = useMutation({
    mutationFn: projectsService.addProject,
    onSuccess: (newProject: UpdatedSandboxProject) => {
      projectCacheUtil.addProjectToCache(newProject)
    },
  })

  const updateProjectMutation = useMutation({
    mutationFn: projectsService.updateProject,
    onSuccess(updatedProject: UpdatedSandboxProject) {
      projectCacheUtil.updateProjectInCache(updatedProject)
    },
  })

  const deleteProjectMutation = useMutation({
    mutationFn: projectsService.deleteProject,
    onSuccess(title: string) {
      projectCacheUtil.deleteProjectInCache(title)
    },
  })
  const deleteLastProjectMutation = useMutation({
    mutationFn: projectsService.deleteProject,
    async onSuccess(title: string) {
      await router.push(`/credentials`)
      showSuccessToast(`${title} has been deleted`)
      projectCacheUtil.deleteProjectInCache(title)
    },
  })

  const resetProjectKeysMutation = useMutation({
    mutationFn: projectsService.resetProjectKeys,
    onSuccess(updatedProject: UpdatedSandboxProject) {
      projectCacheUtil.updateProjectInCache(updatedProject)
    },
  })

  const addProductToProjectMutation = useMutation({
    mutationFn: projectsService.addProductToProject,
    onSuccess: (updatedProject: UpdatedSandboxProject) => {
      projectCacheUtil.updateProjectInCache(updatedProject)
    },
  })

  const updateProductFromProjectMutation = useMutation({
    mutationFn: projectsService.updateProductFromProject,
    onSuccess: (updatedProject: UpdatedSandboxProject) => {
      projectCacheUtil.updateProjectInCache(updatedProject)
    },
  })

  const deleteProductFromProjectMutation = useMutation({
    mutationFn: projectsService.deleteProductFromProject,
    onSuccess({
      projectName,
      productName,
    }: {
      projectName: string
      productName: string
    }) {
      projectCacheUtil.deleteProductFromProjectInCache(projectName, productName)
    },
  })
  return {
    addProjectMutation,
    updateProjectMutation,
    deleteProjectMutation,
    resetProjectKeysMutation,
    addProductToProjectMutation,
    updateProductFromProjectMutation,
    deleteProductFromProjectMutation,
    deleteLastProjectMutation
  }
}
