import {
  UpdatedSandboxProject,
  UpdatedProjectApiProduct,
} from '@/modules/credentials/projects/projectDetails/projectDetails.model'

import { useQueryClient } from '@tanstack/react-query'

export const PROJECTS_QUERY_KEY = 'SANDBOX-PROJECTS'

export const PRODUCTS_QUERY_KEY = 'SANDBOX-PRODUCTS'

export const useProjectsCacheUtil = () => {
  const queryClient = useQueryClient()

  const addProjectToCache = (newProject: UpdatedSandboxProject) => {
    queryClient.setQueryData(
      [PROJECTS_QUERY_KEY],
      (
        currentProjects: UpdatedSandboxProject[] | undefined
      ): UpdatedSandboxProject[] => {
        return currentProjects ? [...currentProjects, newProject] : [newProject]
      }
    )
  }

  const updateProjectInCache = (updatedProject: UpdatedSandboxProject) => {
    queryClient.setQueryData(
      [PROJECTS_QUERY_KEY],
      (
        currentProjects: UpdatedSandboxProject[] | undefined
      ): UpdatedSandboxProject[] => {
        if (currentProjects) {
          return currentProjects.map((project: UpdatedSandboxProject) => {
            return project.appId === updatedProject.appId
              ? updatedProject
              : project
          })
        }
        return []
      }
    )
  }

  const deleteProjectInCache = (title: string) => {
    queryClient.setQueryData(
      [PROJECTS_QUERY_KEY],
      (
        currentProjects: UpdatedSandboxProject[] | undefined
      ): UpdatedSandboxProject[] => {
        if (currentProjects) {
          return currentProjects.filter(
            (project: UpdatedSandboxProject) => project.projectName !== title
          )
        }
        return []
      }
    )
  }

  const deleteProductFromProjectInCache = (
    projectName: string,
    productName: string
  ) => {
    queryClient.setQueryData(
      [PROJECTS_QUERY_KEY],
      (
        currentProjects: UpdatedSandboxProject[] | undefined
      ): UpdatedSandboxProject[] => {
        if (currentProjects) {
          return currentProjects.map(
            (currentProject: UpdatedSandboxProject): UpdatedSandboxProject => {
              if (currentProject.projectName === projectName) {
                const updatedApiProducts: UpdatedProjectApiProduct[] =
                  currentProject.apiProducts.filter(
                    (apiProduct: UpdatedProjectApiProduct) =>
                      apiProduct.apiproduct !== productName
                  )
                const updatedProject: UpdatedSandboxProject = {
                  ...currentProject,
                  apiProducts: updatedApiProducts,
                }
                return updatedProject
              }
              return currentProject
            }
          )
        }
        return []
      }
    )
  }
  return {
    addProjectToCache,
    updateProjectInCache,
    deleteProjectInCache,
    deleteProductFromProjectInCache,
  }
}
