import { User } from '@/models/user.model'
import { ResetSandboxSuccessResponse } from '@/modules/api/sandbox-apigee/products/reset/route-handler'
import { CreateProjectCTAFormValues } from '@/modules/credentials/projects/components/CreateSandboxProjectCTA/CreateSandboxProjectCTA.model'
import { UpdatedSandboxProject } from '@/modules/credentials/projects/projectDetails/projectDetails.model'
import axios, { AxiosResponse } from 'axios'
import { SandboxEnabledProduct } from '@/modules/api/sandbox-apigee/products/sandbox-list/aem-product.model'

const getProjects = async (): Promise<UpdatedSandboxProject[]> => {
  const res: AxiosResponse<UpdatedSandboxProject[]> = await axios.get(
    '/api/sandbox/projects'
  )
  return res.data
}

const addProject = async ({
  userInfo,
  values,
}: {
  userInfo: User
  values: CreateProjectCTAFormValues
}): Promise<UpdatedSandboxProject> => {
  const user = {
    username: userInfo?.username,
    email: userInfo?.email,
    firstName: userInfo?.firstName,
    lastName: userInfo?.lastName,
    role: userInfo?.role,
  }

  const query = JSON.stringify({
    displayName: values.projectName,
    apiProducts: values.productName,
    description: values.description,
    url: values.url,
    name: values.projectName,
    user: user,
  })

  const res: AxiosResponse<UpdatedSandboxProject> = await axios.post(
    '/api/sandbox/projects',
    query,
    {
      headers: { 'content-type': 'application/json' },
    }
  )
  return res.data
}

const updateProject = async ({
  description,
  url,
  projectName,
}: {
  description: string
  url: string
  projectName: string
}): Promise<UpdatedSandboxProject> => {
  const query = JSON.stringify({
    description: description,
    url: url,
  })

  const res: AxiosResponse<UpdatedSandboxProject> = await axios.put(
    `/api/sandbox/projects/${projectName}`,
    query,
    {
      headers: { 'content-type': 'application/json' },
    }
  )
  return res.data
}

const deleteProject = async (projectName: string): Promise<string> => {
  const res: AxiosResponse<{ message: string }> = await axios.delete(
    `/api/sandbox/projects/${projectName}`
  )
  return projectName
}

const resetProjectKeys = async (
  projectName: string
): Promise<UpdatedSandboxProject> => {
  const res: AxiosResponse<UpdatedSandboxProject> = await axios.put(
    `/api/sandbox/projects/${projectName}/keys`
  )
  return res.data
}

const addProductToProject = async ({
  userInfo,
  projectDetail,
  productName,
}: {
  userInfo: User
  projectDetail: UpdatedSandboxProject
  productName: string
}): Promise<UpdatedSandboxProject> => {
  const user = {
    username: userInfo.username,
    email: userInfo.email,
    firstName: userInfo.firstName,
    lastName: userInfo.lastName,
    role: userInfo.role,
  }

  const query = JSON.stringify({
    apiProduct: productName,
    user: user,
  })
  const res: AxiosResponse<UpdatedSandboxProject> = await axios.put(
    `/api/sandbox/projects/${projectDetail.projectName}/keys/${projectDetail.credentials.consumerKey}/products`,
    query,
    {
      headers: { 'content-type': 'application/json' },
    }
  )
  return res.data
}

const deleteProductFromProject = async ({
  projectName,
  consumerKey,
  productName,
}: {
  projectName: string
  consumerKey: string
  productName: string
}): Promise<{ projectName: string; productName: string }> => {
  const res: AxiosResponse<{ message: string }> = await axios.delete(
    `/api/sandbox/projects/${projectName}/keys/${consumerKey}/products/${productName}`
  )
  return { projectName, productName }
}

const resetProductSandbox = async ({
  projectName,
  sandboxProductName,
}: {
  projectName: string
  sandboxProductName: string
}): Promise<ResetSandboxSuccessResponse> => {
  const query = JSON.stringify({
    projectName,
    sandboxProductName,
  })
  const res: AxiosResponse<ResetSandboxSuccessResponse> = await axios.post(
    `/api/sandbox/products/reset`,
    query,
    {
      headers: { 'content-type': 'application/json' },
    }
  )
  return res.data
}

const updateProductFromProject = async ({
  projectName,
  consumerKey,
  productName,
  upgradeProductName,
}: {
  projectName: string
  consumerKey: string
  productName: string
  upgradeProductName: string
}): Promise<UpdatedSandboxProject> => {
  const query = JSON.stringify({
    upgradeProductInfo: {
      apiProduct: upgradeProductName,
    },
  })

  const res: AxiosResponse<UpdatedSandboxProject> = await axios.post(
    `/api/sandbox/projects/${projectName}/keys/${consumerKey}/products/${productName}`,
    query,
    {
      headers: { 'content-type': 'application/json' },
    }
  )
  return res.data
}

const getSandboxProducts = async (): Promise<SandboxEnabledProduct[]> => {
  const res: AxiosResponse<{ data: SandboxEnabledProduct[] }> = await axios.get(
    `/api/sandbox/products`
  )
  return res.data.data
}

// const getDocumentationlink = async (apiProducts: any) => {
//   console.log('ch ', apiProducts[0].apiproduct)
//   const query: any = JSON.stringify({
//     apiProducts,
//   })
//   console.log('jsr ', query)
//   const headers = {
//     'content-type': 'application/json',
//     query,
//   }
  
//   const res: AxiosResponse = await axios.get(
//     `/api/sandbox/products/documentationLinks`,
//     {
//       headers,
//       // proxy: false,
//     }
//   )
//   return res.data
// }
const s = {
  getProjects,
  addProject,
  updateProject,
  deleteProject,
  resetProjectKeys,
  addProductToProject,
  updateProductFromProject,
  deleteProductFromProject,
  resetProductSandbox,
  getSandboxProducts,
  //getDocumentationlink
}
export default s
