import { UpdatedSandboxProject } from '@/modules/credentials/projects/projectDetails/projectDetails.model'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import projectsService from './sandbox-projects.service'
import {
  PROJECTS_QUERY_KEY,
  PRODUCTS_QUERY_KEY,
} from './sandbox-projects-cache.util'
import { SandboxEnabledProduct } from '@/modules/api/sandbox-apigee/products/sandbox-list/aem-product.model'

export const useProjectsQuery = () => {
  const useFetchProjectsQuery = (): UseQueryResult<
    UpdatedSandboxProject[],
    unknown
  > => {
    return useQuery({
      queryKey: [PROJECTS_QUERY_KEY],
      queryFn: projectsService.getProjects,
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false
    })
  }

  /* 
  USAGE:
  SCENARIO 1: 
    if user visits the projects-list page first, 
    by default the entire list is fetched & cached 
    then if the user visits a specific project-page, we pick the required project from already existing project-list cache
  SCENARIO 2: 
    if user visits a specific project-detail page first, 
    entire list is fetched & cached, 
    then we pick the required project from the project-list cache
  ADVANTAGE:
    this way we only deal with a single cache i.e., PROJECT-LIST 
    & have a single source of truth preventing bugs
   */
  const useFetchProjectDetailsQuery = (
    projectName: string
  ): {
    isLoading: boolean
    projectDetail?: UpdatedSandboxProject
    error?: boolean
    fetchError?: boolean
    projectNotFound?: boolean
  } => {
    // STEP 1: fetch all projects & store it in cache
    const {
      isLoading,
      data: projectsList,
      error: errorFetchingProjectList,
    } = useFetchProjectsQuery()

    // STEP 2: return isLoading = TRUE while fetching
    if (isLoading) {
      return { isLoading }
    }

    // STEP 3: if projectList fetch fails, return error & fetchError as TRUE
    if (errorFetchingProjectList) {
      return {
        isLoading,
        error: true,
        fetchError: true,
      }
    }

    // STEP 4: if projectList fetch is success, find the required project in the list
    const projectDetail: UpdatedSandboxProject | undefined = projectsList?.find(
      (project: UpdatedSandboxProject) => project.projectName === projectName
    )

    // STEP 5: if project find fails, return error & projectNotFound as TRUE
    if (!projectDetail) {
      return {
        isLoading,
        error: true,
        projectNotFound: true,
      }
    }

    // STEP 6: if project find is success, return the project
    return { isLoading, projectDetail }
  }

  return {
    useFetchProjectsQuery,
    useFetchProjectDetailsQuery,
  }
}

export const useProductsQuery = () => {
  const useSandboxEnabledProductsQuery = (): UseQueryResult<
    SandboxEnabledProduct[],
    unknown
  > => {
    return useQuery({
      queryKey: [PRODUCTS_QUERY_KEY],
      queryFn: projectsService.getSandboxProducts,
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false
    })
  }

  return {
    useSandboxEnabledProductsQuery,
  }
}
